import { createSlice ,current} from '@reduxjs/toolkit';

export const cardSlice = createSlice({
    name: 'card',
    initialState: {
        items: []
    },

    reducers: {
        addItem: (state, action) => {

            const existingItemIndex = state.items.findIndex(item => item.id === action.payload.id);
            if (existingItemIndex !== -1) {
                state.items[existingItemIndex] = {
                    ...state.items[existingItemIndex],
                    quantity: state.items[existingItemIndex].quantity + 1
                };
            }  else {

                let object ={
                    ...action.payload,
                    quantity : 1
                }
                state.items.push(object);
            }
        },

        removeItem: (state, action) => {
            const existingItemIndex = current(state.items).findIndex(item => item.id === action.payload.id);
            if (existingItemIndex !== -1) {
                if (state.items[existingItemIndex].quantity > 1) {
                    state.items[existingItemIndex].quantity -= 1;
                } else {
                    state.items.splice(existingItemIndex, 1);
                }
            }
        },

        removeOneCompleteItem:(state, action)=>{
            const updatedItems = state.items.filter(item => item.id !== action.payload);
            return {
                ...state,
                items: updatedItems
            };
        },

        allRemoveItem:(state, action) => {
            state.items = []
        },

        AddAllItem:(state, action) => {
            state.items = action.payload
        }
    },
});

export const { addItem, removeItem ,allRemoveItem,removeOneCompleteItem,AddAllItem} = cardSlice.actions;

export default cardSlice.reducer;
