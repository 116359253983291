import { createSlice, current } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token_data: '',
    token_id: '',
    user_id: '',
    login: false,
    user_name: '',
    issue_dt: null,
  },

  reducers: {
    setToken: (state, action) => {
      state.token_data = action.payload.token_data;
      state.token_id = action.payload.token_id;
      state.user_id = action.payload.user_id;
      state.login = true;
      state.user_name = action.payload.user_name;
      state.issue_dt = new Date();
    },

    resetToken: (state, action) => {
      state.token_data = '';
      state.token_id = '';
      state.user_id = '';
      state.login = false;
      state.user_name = '';
      state.issue_dt = null;
    },
    logout: (state) => {
      state.user = null;
      state.isAuth = false; // Reset isAuth to false
      //state.isAuthReq = false; // isAuth required for visiting the page to false
      //state.isCompSelected = false; // Reset isAuth to false
      state.status = 'idle';
      state.error = null;
      state.token = null;
      state.token_id = null;
      localStorage.clear();
    },
    login: (state, action) => {
      state.user = action.payload.user;
      state.isAuth = true; // Set isAuth to true on successful login
      state.status = 'success';
      state.error = null;
      state.token = action.payload.token;
      state.token_id = action.payload.token_id;

      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token_id', action.payload.token_id);
      localStorage.setItem('status', 'success');
      localStorage.setItem('isAuth', true);
      localStorage.setItem('issueDate', new Date().getDate());
    },
  },
});

export const { logout, login, setToken, resetToken } = authSlice.actions;

export default authSlice.reducer;
