import React, { Suspense } from 'react';
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import './assets/scss/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import Loader from './layouts/loader/Loader';
import PdfView from './views/TcIssueMasterDetail/PdfView';
import { Provider } from 'react-redux';
import { store } from './reducetool/store';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        {/* <PdfView admission_Data={null} tc_Issue_Master_Data={null}> */}
        <App />
        {/* </PdfView> */}
      </BrowserRouter>
    </Suspense>
  </Provider>

  // document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
