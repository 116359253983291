import { Route, Routes, useRoutes, Navigate } from 'react-router-dom';
//import Themeroutes from './routes/Router'
import { lazy } from 'react';
import { useSelector } from 'react-redux';

const FullLayout = lazy(() => import('./layouts/FullLayout.js'));

/***** Pages ****/

const Starter = lazy(() => import('./views/Starter.js'));
// const About = lazy(() => import('./views/About.js'));
// const Alerts = lazy(() => import('./views/ui/Alerts'));
// const Badges = lazy(() => import('./views/ui/Badges'));
// const Buttons = lazy(() => import('./views/ui/Buttons'));
// const Cards = lazy(() => import('./views/ui/Cards'));
// const Grid = lazy(() => import('./views/ui/Grid'));
// const Tables = lazy(() => import('./views/ui/Tables'));
// const Forms = lazy(() => import('./views/ui/Forms'));
// const Breadcrumbs = lazy(() => import('./views/ui/Breadcrumbs'));

// const AddSale = lazy(()=> import("../views/Sales/AddSale.jsx"))
// const SaleDetail = lazy(()=> import("../views/Sales/SaleDetail.jsx"))
// const EditSale = lazy(()=> import("../views/Sales/EditSale.jsx"))

// const Category = lazy(()=> import("../views/Category/Category.jsx"))
// const AddCategory = lazy(()=> import("../views/Category/AddCategory.jsx"))
// const EditCategory = lazy(()=> import("../views/Category/EditCategory.jsx"))

const FeeHead = lazy(() => import('./views/FeeHeadMaster/FeeHead.jsx'));
const AddFeeHead = lazy(() => import('./views/FeeHeadMaster/AddFeeHead.jsx'));
const EditFeeHead = lazy(() => import('./views/FeeHeadMaster/EditFeeHead.jsx'));
const Circle = lazy(() => import('./views/CircleMaster/Circle.jsx'));
const AddCircleMaster = lazy(() =>
  import('./views/CircleMaster/AddCircleMaster.jsx')
);
const Admission = lazy(() => import('./views/AdmissionMaster/Admission.jsx'));
const AddAdmission = lazy(() =>
  import('./views/AdmissionMaster/AddAdmission.jsx')
);
const Slab = lazy(() => import('./views/SlabMaster/Slab.jsx'));
const AddSlabMaster = lazy(() =>
  import('./views/SlabMaster/AddSlabMaster.jsx')
);
const Fee_Group_Master_Details = lazy(() =>
  import('./views/Fee_Group_Master/Fee_Group_Master_Search_Screen.jsx')
);
const Fee_Group_Master_Add = lazy(() =>
  import('./views/Fee_Group_Master/Fee_Group_Master_Add_Edit_Screen.jsx')
);

const Class_Master_Details = lazy(() =>
  import('./views/Class_Master/Class_Master_Search_Screen.jsx')
);
const Class_Master_Add = lazy(() =>
  import('./views/Class_Master/Class_Master_Add_Edit_Screen.jsx')
);

const TcIssue_Master_Details = lazy(() =>
  import('./views/TcIssueMasterDetail/Tc_Issue_Master_Detail_Search_Screen.jsx')
);
const TcIssue_Master_Add = lazy(() =>
  import(
    './views/TcIssueMasterDetail/Tc_Issue_Master_Detail_Add_Edit_Screen.jsx'
  )
);
const Fee_Reciept_Details = lazy(() =>
  import('./views/FeeReciept/Fee_Reciept_Master_Search.jsx')
);
const Fee_Reciept_Add = lazy(() =>
  import('./views/FeeReciept/Fee_Reciept_Master_Add_Edit_Screen.jsx')
);
const SubjectMaster_Details = lazy(() =>
  import('./views/SubjectMaster/Subject_Master_Search.jsx')
);
const SubjectMaster_Add = lazy(() =>
  import('./views/SubjectMaster/Subject_Master_Add_Edit_Screen.jsx')
);
const ClassDetailMaster_Details = lazy(() =>
  import('./views/ClassDetailMaster/Class_Detail_Master_Search.jsx')
);
const ClassDetailMaster_Add = lazy(() =>
  import('./views/ClassDetailMaster/Class_Detail_Master_Add_Edit_Screen.jsx')
);
const ResultMaster_Details = lazy(() =>
  import('./views/ResultMaster/Result_Master_New_Search.jsx')
);
const ResultDetailMaster_Add = lazy(() =>
  import('./views/ResultMaster/Result_Master_New_Add_Edit_Screen.jsx')
);
const AdmisionReport = lazy(() => import('./views/Report/AdmisionReports.jsx'));
const System_Record_Details = lazy(() =>
  import('./views/SystemRecord/SystemRecords.jsx')
);
const System_Record_Add = lazy(() =>
  import('./views/SystemRecord/AddSystemRecord.jsx')
);
const Multipurpose_Details = lazy(() =>
  import('./views/MultiPurposeMaster/Multi_Purpose_Type_Master_Search.jsx')
);
const Multipurpose_Add = lazy(() =>
  import(
    './views/MultiPurposeMaster/Multi_Purpose_Type_Master_Add_Edit_Screen.jsx'
  )
);
const Transport_Details = lazy(() =>
  import('./views/TransportMaster/Transport.jsx')
);
const Transport_Add = lazy(() =>
  import('./views/TransportMaster/Transport_Add_Edit.jsx')
);
const Party_Details = lazy(() => import('./views/PartyMaster/Party.jsx'));
const Party_Add = lazy(() => import('./views/PartyMaster/AddParty.jsx'));
const Party_Edit = lazy(() => import('./views/PartyMaster/EditParty.jsx'));

const AddItem = lazy(() => import('./views/ItemMaster/AddItem.jsx'));
const Item = lazy(() => import('./views/ItemMaster/Item.jsx'));
const EditItem = lazy(() => import('./views/ItemMaster/EditItem.jsx'));

const Party_Master_Details = lazy(() =>
  import('./views/PartyCategoryMaster/PartyCategoryMaster.jsx')
);
const Party_Master_Add_Edit = lazy(() =>
  import('./views/PartyCategoryMaster/AddPartyPartyCategoryMaster.jsx')
);
const Aggreement_Details = lazy(() =>
  import('./views/AggrementMaster/Aggrement.jsx')
);

const Voucher_Add_Edit = lazy(() =>
  import('./views/VoucherMaster/AddVoucher.jsx')
);
const Voucher_Details = lazy(() => import('./views/VoucherMaster/Voucher.jsx'));
const AddBlogMaster_Add_Edit = lazy(() =>
  import('./views/BlogMaster/AddBlogMaster.jsx')
);
const AddBlogMaster_Details = lazy(() =>
  import('./views/BlogMaster/BlogMaster.jsx')
);

const MiniSlider_Add_Edit = lazy(() =>
  import('./views/MiniSliderMaster/AddMiniSliderMaster.jsx')
);
const MiniSlider_Details = lazy(() =>
  import('./views/MiniSliderMaster/MiniSliderMaster.jsx')
);
const Slider_Add_Edit = lazy(() =>
  import('./views/SliderMaster/AddSliderMaster.jsx')
);
const Slider_Details = lazy(() =>
  import('./views/SliderMaster/SliderMaster.jsx')
);
const Client_Add_Edit = lazy(() =>
  import('./views/ClientMaster/AddClientMaster.jsx')
);
const Client_Details = lazy(() =>
  import('./views/ClientMaster/ClientMaster.jsx')
);
const Contact_Add_Edit = lazy(() =>
  import('./views/ContactMaster/AddContactMaster.jsx')
);
const Contact_Details = lazy(() =>
  import('./views/ContactMaster/ContactMaster.jsx')
);
const FaqMaster_Add_Edit = lazy(() =>
  import('./views/FaqMaster/AddFaqMaster.jsx')
);
const FaqMaster_Details = lazy(() => import('./views/FaqMaster/FaqMaster.jsx'));
const SystemRecord_Add_Edit = lazy(() =>
  import('./views/SystemRecord/AddSystemRecord.jsx')
);
const SystemRecord_Details = lazy(() =>
  import('./views/SystemRecord/SystemRecords.jsx')
);

const FormMaster_Add_Edit = lazy(() =>
  import('./views/FormMaster/AddFormMaster.jsx')
);
const FormMaster_Details = lazy(() =>
  import('./views/FormMaster/FormMaster.jsx')
);
//const Viewcontact = lazy(() => import('./views/ContactMaster/Viewcontact.jsx'));
const Circular_Add_Edit = lazy(() =>
  import('./views/CircularMaster/Add_Edit_Screen.jsx')
);
const Circular_Details = lazy(() =>
  import('./views/CircularMaster/Circular_Search_Screen.jsx')
);
const InWard_Add_Edit = lazy(() =>
  import('./views/WardOutMaster/WardOut_Add_Edit.jsx')
);
const WardOut_List = lazy(() =>
  import('./views/WardOutMaster/WardOut_List.jsx')
);
const InWard_Add_Edit_screen = lazy(() =>
  import('./views/WardInMaster/WardIn_Add_Edit.jsx')
);
const WardOut_List_screen = lazy(() =>
  import('./views/WardInMaster/WardIn_List.jsx')
);
const Transaction_Add_Edit_screen = lazy(() =>
  import('./views/TransactionForAllFlag/Transaction_Add_Edit.jsx')
);
const Transaction_List_screen = lazy(() =>
  import('./views/TransactionForAllFlag/Transaction_List.jsx')
);
const Transaction = lazy(() =>
  import('./views/TransactionForAllFlag/Transaction.jsx')
);
const Transac_Pay_Rec_Detail = lazy(() =>
  import('./views/TransactionForAllFlag/Transac_Payment_reciept.jsx')
);
const Transaction_Sales_Purchase = lazy(() =>
  import('./views/TransactionForAllFlag/Transaction_Sales_Purchase.jsx')
);

const Login = lazy(() => import('./views/LoginForm/Login.jsx'));

const FeeReport = lazy(() => import('./views/Report/FeeReport.jsx'));
/*****Routes******/
const App = () => {
  const authData = useSelector((state) => state.authState);

  let ThemeRoutes;
  authData.isAuth
    ? (ThemeRoutes = [
        { path: '/', exact: true, element: <Navigate to='/Login' /> },
        { path: '/Login', exact: true, element: <Login /> },

        {
          path: '/',
          element: <FullLayout />,
          children: [
            { path: '/', element: <Navigate to='/starter' /> },
            { path: '/starter', exact: true, element: <Starter /> },
            // { path: '/about', exact: true, element: <About /> },
            // { path: '/alerts', exact: true, element: <Alerts /> },
            // { path: '/badges', exact: true, element: <Badges /> },
            // { path: '/buttons', exact: true, element: <Buttons /> },
            // { path: '/cards', exact: true, element: <Cards /> },
            // { path: '/grid', exact: true, element: <Grid /> },
            // { path: '/table', exact: true, element: <Tables /> },
            // { path: '/forms', exact: true, element: <Forms /> },
            // { path: '/breadcrumbs', exact: true, element: <Breadcrumbs /> },

            // { path: "/addsale", exact: true, element: <AddSale /> },
            // { path: "/saledetail", exact: true, element: <SaleDetail/> },
            // { path: "/editsale", exact: true, element: <EditSale/> },

            /// -----FeeHead---------------------
            { path: '/FeeHead', exact: true, element: <FeeHead /> },
            { path: '/addFeeHead', exact: true, element: <AddFeeHead /> },
            { path: '/editFeeHead', exact: true, element: <EditFeeHead /> },
            { path: '/Circle', exact: true, element: <Circle /> },
            {
              path: '/addCircleMaster',
              exact: true,
              element: <AddCircleMaster />,
            },
            { path: '/Admission', exact: true, element: <Admission /> },
            { path: '/AddAdmission', exact: true, element: <AddAdmission /> },

            ///SlabMaster
            { path: '/addSlabMaster', exact: true, element: <AddSlabMaster /> },
            { path: '/Slab', exact: true, element: <Slab /> },

            {
              path: '/Fee_Group_Master_Add_Edit_Screen',
              exact: true,
              element: <Fee_Group_Master_Add />,
            },
            {
              path: '/Fee_Group_Master_Search_Screen',
              exact: true,
              element: <Fee_Group_Master_Details />,
            },

            {
              path: '/Class_Master_Add_Edit_Screen',
              exact: true,
              element: <Class_Master_Add />,
            },
            {
              path: '/Class_Master_Search_Screen',
              exact: true,
              element: <Class_Master_Details />,
            },
            {
              path: '/Tc_Issue_Master_Detail_Add_Edit_Screen',
              exact: true,
              element: <TcIssue_Master_Add />,
            },
            {
              path: '/Tc_Issue_Master_Detail_Search_Screen',
              exact: true,
              element: <TcIssue_Master_Details />,
            },
            {
              path: '/Fee_Reciept_Master_Add_Edit_Screen',
              exact: true,
              element: <Fee_Reciept_Add />,
            },
            {
              path: '/Fee_Reciept_Master_Search',
              exact: true,
              element: <Fee_Reciept_Details />,
            },
            {
              path: '/Subject_Master_Add_Edit_Screen',
              exact: true,
              element: <SubjectMaster_Add />,
            },
            {
              path: '/Subject_Master_Search',
              exact: true,
              element: <SubjectMaster_Details />,
            },
            {
              path: '/Class_Detail_Master_Add_Edit_Screen',
              exact: true,
              element: <ClassDetailMaster_Add />,
            },
            {
              path: '/Class_Detail_Master_Search',
              exact: true,
              element: <ClassDetailMaster_Details />,
            },
            {
              path: '/Result_Master_New_Add_Edit_Screen',
              exact: true,
              element: <ResultDetailMaster_Add />,
            },
            {
              path: '/Result_Master_New_Search',
              exact: true,
              element: <ResultMaster_Details />,
            },

            {
              path: '/AdmisionReports',
              exact: true,
              element: <AdmisionReport />,
            },
            {
              path: '/FeeReport',
              exact: true,
              element: <FeeReport />,
            },
            {
              path: '/AddSystemRecord',
              exact: true,
              element: <System_Record_Add />,
            },
            {
              path: '/SystemRecords',
              exact: true,
              element: <System_Record_Details />,
            },
            {
              path: '/Multi_Purpose_Type_Master_Add_Edit_Screen',
              exact: true,
              element: <Multipurpose_Add />,
            },
            {
              path: '/Multi_Purpose_Type_Master_Search',
              exact: true,
              element: <Multipurpose_Details />,
            },
            {
              path: '/Transport_Add_Edit',
              exact: true,
              element: <Transport_Add />,
            },
            {
              path: '/Transport',
              exact: true,
              element: <Transport_Details />,
            },

            { path: '/addparty', exact: true, element: <Party_Add /> },
            { path: '/party', exact: true, element: <Party_Details /> },
            { path: '/editparty', exact: true, element: <Party_Edit /> },

            { path: '/additem', exact: true, element: <AddItem /> },
            { path: '/item', exact: true, element: <Item /> },
            { path: '/edititem', exact: true, element: <EditItem /> },

            {
              path: '/addpartycategory',
              exact: true,
              element: <Party_Master_Add_Edit />,
            },
            {
              path: '/PartyCategoryMaster',
              exact: true,
              element: <Party_Master_Details />,
            },
            {
              path: '/Aggrement',
              exact: true,
              element: <Aggreement_Details />,
            },
            {
              path: '/Voucher',
              exact: true,
              element: <Voucher_Details />,
            },
            {
              path: '/AddVoucher',
              exact: true,
              element: <Voucher_Add_Edit />,
            },
            {
              path: '/BlogMaster',
              exact: true,
              element: <AddBlogMaster_Details />,
            },
            {
              path: '/AddBlogMaster',
              exact: true,
              element: <AddBlogMaster_Add_Edit />,
            },
            {
              path: '/MiniSliderMaster',
              exact: true,
              element: <MiniSlider_Details />,
            },
            {
              path: '/AddMiniSliderMaster',
              exact: true,
              element: <MiniSlider_Add_Edit />,
            },
            {
              path: '/SliderMaster',
              exact: true,
              element: <Slider_Details />,
            },
            {
              path: '/AddSliderMaster',
              exact: true,
              element: <Slider_Add_Edit />,
            },
            {
              path: '/ContactMaster',
              exact: true,
              element: <Contact_Details />,
            },
            {
              path: '/AddContactMaster',
              exact: true,
              element: <Contact_Add_Edit />,
            },
            {
              path: '/FaqMaster',
              exact: true,
              element: <FaqMaster_Details />,
            },
            {
              path: '/AddFaqMaster',
              exact: true,
              element: <FaqMaster_Add_Edit />,
            },
            {
              path: '/ClientMaster',
              exact: true,
              element: <Client_Details />,
            },
            {
              path: '/AddClientMaster',
              exact: true,
              element: <Client_Add_Edit />,
            },
            {
              path: '/SystemRecords',
              exact: true,
              element: <SystemRecord_Details />,
            },
            {
              path: '/AddSystemRecord',
              exact: true,
              element: <SystemRecord_Add_Edit />,
            },
            {
              path: '/FormMaster',
              exact: true,
              element: <FormMaster_Details />,
            },
            {
              path: '/AddFormMaster',
              exact: true,
              element: <FormMaster_Add_Edit />,
            },
            // {
            //   path: '/Viewcontact',
            //   exact: true,
            //   element: <Viewcontact />,
            // },
            {
              path: '/Add_Edit_Screen',
              exact: true,
              element: <Circular_Add_Edit />,
            },
            {
              path: '/Circular',
              exact: true,
              element: <Circular_Details />,
            },

            {
              path: '/WardOut_List',
              exact: true,
              element: <WardOut_List />,
            },
            {
              path: '/WardOut_Add_Edit',
              exact: true,
              element: <InWard_Add_Edit />,
            },

            {
              path: '/WardIn_List',
              exact: true,
              element: <WardOut_List_screen />,
            },
            {
              path: '/WardIn_Add_Edit',
              exact: true,
              element: <InWard_Add_Edit_screen />,
            },
            {
              path: '/Transaction_List',
              exact: true,
              element: <Transaction_List_screen />,
            },
            {
              path: '/Transaction_Add_Edit',
              exact: true,
              element: <Transaction_Add_Edit_screen />,
            },

            {
              path: '/Transaction',
              exact: true,
              element: <Transaction />,
            },
            {
              path: '/Transac_Payment_reciept',
              exact: true,
              element: <Transac_Pay_Rec_Detail />,
            },
            {
              path: '/Transaction_Sales_Purchase',
              exact: true,
              element: <Transaction_Sales_Purchase />,
            },

            // { path: '/', exact: true, element: <Navigate to='/Login' /> },
            // { path: '/Login', exact: true, element: <LoginForm_Details /> },

            //{ path: '/editFeeHead', exact: true, element: <EditFeeHead /> },
            // { path: "/category", exact: true, element: <Category /> },
            // { path: "/addcategory", exact: true, element: <AddCategory /> },
            // { path: "/editcategory", exact: true, element: <EditCategory /> },

            // { path: "/invoice", exact: true, element: <Invoice/> },
            // { path: "/pdf", exact: true, element: <PDFGeneratorButton/> },
          ],
        },
      ])
    : (ThemeRoutes = [
        { path: '/', exact: true, element: <Navigate to='/Login' /> },
        { path: '/Login', exact: true, element: <Login /> },
        { path: '*', exact: true, element: <Navigate to='/Login' /> },
      ]);

  const routing = useRoutes(ThemeRoutes);

  return <div className='dark'>{routing}</div>;
};

export default App;
