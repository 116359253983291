import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from '@react-pdf/renderer';
import backgroundImage from '../../assets/blank.png';

// Create styles
const styles = StyleSheet.create({
  text: {
    position: 'absolute',
    fontSize: '10px',
    // left: '0px',
    // right: '0px',
    // marginHorizontal: 'auto',
    //textAlign: "center",
    //justifyContent: 'center',
  },
});

// Create Document Component
const PdfView = ({ admission_Data, tc_Issue_Master_Data, schoolData }) => (
  <Document>
    <Page wrap size='A4' style={styles.page}>
      <View style={{ backgroundImage: backgroundImage }}>
        <Image src={backgroundImage}></Image>
      </View>

      <Text
        style={{
          top: '10px',
          marginHorizontal: 'auto',
          justifyContent: 'center',
          left: '0px',
          right: '0px',
          textAlign: 'center',
          color: 'green',
          fontStyle: 'Italic',
          fontWeight: 'bold',
          fontSize: '20px',
          position: 'absolute',
        }}
      >
        {schoolData?.school_name}
      </Text>
      <Text
        style={{
          top: '30px',
          justifyContent: 'center',
          left: '0px',
          right: '0px',
          textAlign: 'center',
          fontSize: '15px',
          position: 'absolute',
        }}
      >
        (Recognised)
      </Text>
      <Text
        style={{
          top: '60px',
          marginHorizontal: 'auto',
          justifyContent: 'center',
          left: '0px',
          right: '0px',
          textAlign: 'center',
          fontSize: '10px',
          position: 'absolute',
        }}
      >
        {schoolData?.address}
      </Text>
      <Text
        style={{
          top: '70px',
          marginHorizontal: 'auto',
          justifyContent: 'center',
          left: '0px',
          right: '0px',
          textAlign: 'center',
          fontSize: '10px',
          position: 'absolute',
        }}
      >
        {schoolData?.state},{schoolData?.city},{schoolData?.pincode}
      </Text>
      <Text
        style={{
          top: '80px',
          marginHorizontal: 'auto',
          justifyContent: 'center',
          left: '0px',
          right: '0px',
          textAlign: 'center',
          color: 'blue',
          fontSize: '15px',
          position: 'absolute',
        }}
      >
        ORIGINAL COPY
      </Text>

      <Text style={{ top: '85px', left: '80px', ...styles.text }}>A56421</Text>

      <Text style={{ top: '145px', left: '150px', ...styles.text }}>
        {admission_Data.sch_session}
      </Text>
      <Text style={{ top: '140px', left: '450px', ...styles.text }}>
        {admission_Data.file_no_1}
      </Text>
      <Text style={{ top: '163px', left: '450px', ...styles.text }}>
        {admission_Data.admit_no}
      </Text>
      <Text style={{ top: '187px', left: '150px', ...styles.text }}>
        {admission_Data.stud_name}
      </Text>
      <Text style={{ top: '228px', left: '150px', ...styles.text }}>
        {admission_Data.d_o_b}
      </Text>
      <Text style={{ top: '228px', left: '290px', ...styles.text }}>
        {admission_Data.d_o_b}
      </Text>
      <Text style={{ top: '250px', left: '150px', ...styles.text }}>
        {admission_Data.stud_name}
      </Text>
      <Text style={{ top: '275px', left: '150px', ...styles.text }}>
        {admission_Data.f_name}
      </Text>
      <Text style={{ top: '300px', left: '150px', ...styles.text }}>
        {admission_Data.m_name}
      </Text>
      <Text style={{ top: '323px', left: '250px', ...styles.text }}>
        {admission_Data.last_attended_date}
      </Text>
      <Text style={{ top: '350px', left: '100px', ...styles.text }}>
        {admission_Data.curr_class}
      </Text>
      <Text style={{ top: '350px', left: '250px', ...styles.text }}>
        {admission_Data.sys_remark}
      </Text>
      <Text style={{ top: '375px', left: '285px', ...styles.text }}>
        xxxxxxx
      </Text>
      <Text style={{ top: '375px', left: '405px', ...styles.text }}>
        xxxxxxx
      </Text>
      <Text style={{ top: '420px', left: '320px', ...styles.text }}>
        PASSED AND PROMOTED
      </Text>
      <Text
        style={{
          top: '450px',
          left: '270px',
          color: 'green',
          fontStyle: 'Italic',
          fontWeight: 'bold',
          fontSize: '20px',
          position: 'absolute',
        }}
      >
        {schoolData?.school_name}
      </Text>
      <Text
        style={{
          top: '565px',
          left: '40px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {schoolData?.school_name}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '40px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {schoolData?.address}
      </Text>
      <Text
        style={{
          top: '585px',
          left: '40px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {schoolData?.state},{schoolData?.city},{schoolData?.pincode}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '130px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.adm_date}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '200px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.withdraw_date}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '270px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.from_year}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '320px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.to_year}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '370px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.possible_attend_days}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '440px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.attendance_days}
      </Text>
      <Text
        style={{
          top: '575px',
          left: '510px',
          width: 100,
          fontSize: '8px',
          flexShrink: 1,
          position: 'absolute',
        }}
      >
        {admission_Data.leave_taken_days}
      </Text>
      <Text style={{ top: '630px', left: '130px', ...styles.text }}>
        {admission_Data.tc_issue_date}
      </Text>
      <Text style={{ top: '653px', left: '120px', ...styles.text }}>
        {schoolData?.prepared_by}
      </Text>
      <Text style={{ top: '650px', left: '440px', ...styles.text }}>
        {schoolData?.checked_by}
      </Text>
      <Text style={{ top: '678px', left: '120px', ...styles.text }}>
        {schoolData?.head_of_institution}
      </Text>
    </Page>
  </Document>
);
export default PdfView;
