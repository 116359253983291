import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from "redux"; 
import cardSlice from './features/cardSlice';
import authSlice from './features/authSlice';
import companySlice from './features/companySlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  cardState: cardSlice,
  authState : authSlice,
  companyState :  companySlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };


// export default configureStore({
//   reducer: {
//     counter: counterReducer,
//     editService : editServiceSlice,
//     newservice : serviceSlice

//   }
// })
